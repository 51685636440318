/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { DateRange, EstatePeriod, Granularity } from "~/components";
import { dateFormat } from "~/constants";
import { useQueryState } from "~/hooks";
import { useBillingPeriod } from "~/services/customers";
import { EstateChartsQueryState } from "../types";

export function useEstateChartParameters() {
  const { state, updateQueryState } = useQueryState<EstateChartsQueryState>();

  const { isPreviousBillingPeriod } = useBillingPeriod();

  const [period, setPeriod] = useState<EstatePeriod>(
    state.estatePeriod ?? EstatePeriod.DEFAULT_MONTH
  );
  const [search, setSearch] = useState("");

  const [isDataLoading, setIsDataLoading] = useState(false);

  const [range, setRange] = useState<DateRange | undefined>(undefined);

  const granularity = state.granularity ?? Granularity.MONTHS;

  const handleGranularityChange = (granularitySelection: Granularity) => {
    updateQueryState({
      granularity: granularitySelection,
    });
  };

  const clearRange = () => {
    setRange(undefined);
  };

  const handlePeriodChange = (
    selectedPeriod: EstatePeriod,
    selectedRange?: DateRange
  ) => {
    setPeriod(selectedPeriod);
    if (selectedRange) {
      setRange(selectedRange);
    }

    updateQueryState({
      periodStart: getStartDate(
        selectedPeriod,
        isPreviousBillingPeriod,
        selectedRange
      ),
      periodEnd: getEndDate(
        selectedPeriod,
        isPreviousBillingPeriod,
        selectedRange
      ),
      estatePeriod: selectedPeriod,
    });
  };

  useEffect(() => {
    if (period !== EstatePeriod.RANGE) {
      handlePeriodChange(period);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPreviousBillingPeriod]);

  return {
    period,
    granularity,
    range,
    handleGranularityChange,
    handlePeriodChange,
    search,
    setSearch,
    clearRange,
    isDataLoading,
    setIsDataLoading,
  };
}

function getStartDate(
  period: EstatePeriod,
  isPreviousBillingPeriod: boolean,
  range: DateRange | undefined
) {
  const monthStart = isPreviousBillingPeriod
    ? generatePreviousPeriodDate(true)
    : generateCurrentPeriodDate(true);

  if (period === EstatePeriod.RANGE) {
    return range?.from ?? monthStart;
  }

  return period === EstatePeriod.PREVIOUS_MONTH
    ? dayjs(monthStart)
        .subtract(1, "month")
        .startOf("month")
        .format(dateFormat.shortDate)
    : monthStart;
}

function getEndDate(
  period: EstatePeriod,
  isPreviousBillingPeriod: boolean,
  range: DateRange | undefined
) {
  const monthEnd = isPreviousBillingPeriod
    ? generatePreviousPeriodDate(false)
    : generateCurrentPeriodDate(false);

  if (period === EstatePeriod.RANGE) {
    return range?.to ?? monthEnd;
  }

  return period === EstatePeriod.PREVIOUS_MONTH
    ? dayjs(monthEnd)
        .subtract(1, "month")
        .endOf("month")
        .format(dateFormat.shortDate)
    : monthEnd;
}

function generatePreviousPeriodDate(isStartDate: boolean) {
  if (isStartDate) {
    return dayjs()
      .subtract(1, "month")
      .startOf("month")
      .format(dateFormat.shortDate);
  }

  return dayjs()
    .subtract(1, "month")
    .endOf("month")
    .format(dateFormat.shortDate);
}

function generateCurrentPeriodDate(isStartDate: boolean) {
  if (isStartDate) {
    return dayjs().startOf("month").format(dateFormat.shortDate);
  }
  return dayjs().endOf("month").format(dateFormat.shortDate);
}
