/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useEffect, useMemo, useState } from "react";
import { When } from "react-if";
import { Box } from "@nordcloud/gnui";
import {
  BreadcrumbsBox,
  BrickLoader,
  DataDisplayWrapper,
  NoData,
  Pagination,
  ReactTableV2,
  UniversalWrap,
} from "~/components";
import { useQueryState } from "~/hooks";
import { SearchSortOrder, isNil, isNotEmpty, isNotNil } from "~/tools";
import { ThresholdsListColumns } from "./ThresholdsListColumns";
import { Threshold, ThresholdsQueryState } from "./types";

export function ThresholdsPage() {
  const { state, updateQueryState } = useQueryState<ThresholdsQueryState>();
  const { column, order, limit, page } = state;

  const isLoading = false;
  const [thresholds, setThresholds] = useState<Threshold[]>([]);

  const breadcrumbLabels = [{ value: "thresholds", label: "Thresholds" }];

  const data = [
    {
      customerId: "0",
      id: "0",
      name: "abc",
      thresholdAmount: "5",
      granularity: "monthly",
      userRecipients: ["0", "1"],
      externalRecipients: [""],
      createdDate: "2023-09-13T11:16:54Z",
      updatedDate: "2023-09-14T11:16:54Z",
      monthlyNotificationSentDate: "",
      providerIds: [""],
      accountIds: [""],
      serviceIds: [""],
    },
    {
      customerId: "0",
      id: "1",
      name: "def",
      thresholdAmount: "7",
      granularity: "daily",
      userRecipients: ["0"],
      externalRecipients: [""],
      createdDate: "2023-09-11T11:16:54Z",
      updatedDate: "2023-09-12T11:16:54Z",
      monthlyNotificationSentDate: "",
      providerIds: [""],
      accountIds: [""],
      serviceIds: [""],
    },
    {
      customerId: "0",
      id: "0",
      name: "ghi",
      thresholdAmount: "2",
      granularity: "daily",
      userRecipients: ["3", "4"],
      externalRecipients: [""],
      createdDate: "2023-09-09T11:16:54Z",
      updatedDate: "2023-09-09T11:16:54Z",
      monthlyNotificationSentDate: "",
      providerIds: [""],
      accountIds: [""],
      serviceIds: [""],
    },
  ];

  useEffect(() => {
    if (!isLoading && isNil(column) && isNil(order)) {
      updateQueryState({
        column: "name",
        order: SearchSortOrder.Dsc,
      });
    }
  }, [isLoading, column, order, updateQueryState]);

  useEffect(() => {
    setThresholds(data.slice(page * limit, (page + 1) * limit));
    // Dependencies have to be like this to avoid infinite render loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, page, order]);

  const thresholdCount = data.length;

  const columns = useMemo(() => ThresholdsListColumns(), []);

  const onSort = (sort: { id: string; desc: boolean }) => {
    updateQueryState({
      ...state,
      column: sort.id,
      order: sort.desc ? SearchSortOrder.Dsc : SearchSortOrder.Asc,
      page: 0,
    });
  };

  const defaultSort = {
    id: column ?? "name",
    desc: (isNotNil(order) ? order === SearchSortOrder.Dsc : false) ?? true,
  };

  return (
    <>
      <BreadcrumbsBox title="Thresholds" labels={breadcrumbLabels} />
      <UniversalWrap
        // errorProps={{ error }}
        errorProps={{}}
        loaderProps={{
          loading: false,
          Component: BrickLoader,
        }}
      >
        <DataDisplayWrapper
          hasData={isNotEmpty(data)}
          renderNoData={(message) => (
            <Box>
              <NoData message={message} />
            </Box>
          )}
        >
          <Box spacing="spacing04">
            <ReactTableV2
              striped
              sort
              columns={columns}
              data={thresholds || []}
              defaultSort={defaultSort}
              onSort={onSort}
            />
            <When condition={thresholdCount > 0}>
              <Pagination count={thresholdCount} />
            </When>
          </Box>
        </DataDisplayWrapper>
      </UniversalWrap>
    </>
  );
}
