/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Mode } from "~/components";
import { isNotEmpty } from "~/tools";

export function getNumberFilterValue(value: string): number | undefined {
  return isNotEmpty(value) ? Number(value) : undefined;
}

export function isDoubleFilterSelected(from?: string, to?: string) {
  return isFilterSelected(from) || isFilterSelected(to);
}

function isFilterSelected(value?: string) {
  return isNotEmpty(value ?? "");
}

export function isExcluded(mode: Mode) {
  return mode === Mode.EXCLUDE ? true : undefined;
}
