/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Cell } from "@tanstack/react-table";
import dayjs from "dayjs";
import { Button } from "@nordcloud/gnui";
import { ACTION, PopoverControls } from "~/components";
import { dateFormat } from "~/constants";
import { Threshold } from "./types";

export function ThresholdsListColumns() {
  const popoverActions = [
    {
      label: "Edit Threshold",
      action: ACTION.EDIT,
      onClick: () => {},
    },
    {
      label: "Delete Threshold",
      action: ACTION.DELETE,
      onClick: () => {},
    },
  ];

  return [
    {
      id: "name",
      accessorKey: "name",
      header: <div css={{ minWidth: "10rem" }}>Name</div>,
      cell: ({ row }: Cell<Threshold>) => {
        return <span>{row.original?.name}</span>;
      },
    },
    {
      id: "data",
      header: "Data",
      cell: () => {
        return <span>Data</span>;
      },
    },
    {
      id: "recipients",
      header: "Recipients",
      cell: () => {
        return <span>recipient</span>;
      },
    },
    {
      id: "amountGranularity",
      header: "Amount / Granularity",
      cell: () => {
        return <span>xxx/nnn</span>;
      },
    },
    {
      id: "creationDate",
      accessorKey: "creationDate",
      header: "Creation Date",
      cell: ({ row }: Cell<Threshold>) => {
        return (
          <span>
            {dayjs(row.original?.createdDate).format(dateFormat.fullDateShort)}
          </span>
        );
      },
    },
    {
      id: "actions",
      header: "",
      meta: { width: "2rem" },
      cell: () => {
        return (
          <PopoverControls
            items={popoverActions}
            trigger={<Button severity="low" icon="menu" size="md" />}
          />
        );
      },
    },
  ];
}
